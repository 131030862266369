.ambiance-card {
    flex: 1;
    min-width: 200px;
    max-width: 250px;
    padding: 10px;
    margin: 10px; 
    background: rgba(255, 209, 193, 0.2);
    border: 4px solid transparent; 
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 209, 193, 0.3);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; 
  }

  @keyframes blinking-border {
    0%, 100% {
      border-color: transparent;
    }
    50% {
      border-color: #474747;
    }
  }
  
  .ambiance-card.playing {
    animation: blinking-border 5s ease-in-out infinite;
  }

  .ambiance-card:hover {
    transform: scale(1.05); 
    box-shadow: 0 8px 60px rgba(0, 0, 0, 0.2);
  }
  
  .ambiance-image {
    width: 50%;
    object-fit: cover;
    top: 50px;
    margin-top: 50px;
    border-radius: 10px;
    color: #474747;
  }
  
  .ambiance-name {
    color: #474747;
    padding: 20px;
  }

