.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../public/background.webp');
  background-color: #282c34;
  background-size: cover; 
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
}

.App-header {
  color: #474747;
  width: 100%;
}

.logo-image {
  min-width: 30vw;
  max-width: 60vw;
  margin-top: -5%;
}

.App-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; 
  gap: 64px; 
  margin: 64px;
  margin-top: -1%;
  max-width: 1000px;
}

.footer {
  color: #474747;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: small;
  font-weight: 600;
}